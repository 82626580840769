import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpService } from '../../shared/services/http.service';

@Injectable({
    providedIn: 'root',
})
export class FetchApiDataService {
    public static selectedDay = new Subject();
    public bulkUploadDetails = new Subject<any>();
    public bulkUploadStatus = new Subject<any>();

    constructor(private _http: HttpService) {}

    loadAllPrograms(data: any) {
        return this._http.post('loadAllPrograms', data);
    }

     // returns programs which has campaings
    loadAllProgramswithCampaign(data: any) {
        return this._http.post('getProgramswithCampaign', data);
    }

    queryAllData(data: any) {
        return this._http.post('queryAllData', data);
    }

    downloadExtractData(data: any) {
        return this._http.post('downloadExtractData', data);
    }

    getUserData() {
        return this._http.get('getUserData');
    }

    loadCamps(data) {
        return this._http.post('getCampaignsByProgramIDandUserInfo', data);
    }

    loadTactics(entity_type, cid) {
        if (entity_type === 'program') {
            return this._http.get('getTacticsByProgramID', cid);
        } else if (entity_type === 'campaign') {
            return this._http.get('getTacticByCampaignID', cid);
        } else if (entity_type === 'tactic') {
            return this._http.get('getTacticByTacticID', cid);
        }
    }

    getProgramByProgramID(pid) {
        return this._http.get('getprogrambyprogramid', pid);
    }

    getCampaignsByProgramIDandUserInfo(data) {
        return this._http.post('getCampaignsByProgramIDandUserInfo', data);
    }

    getCampaignByCampaignID(id): Observable<any> {
        return this._http.get('getCampaignByCampaignID', id);
    }

    deleteProgram(data) {
        return this._http.post('deleteProgramData', data);
    }

    deleteCampaign(data) {
        return this._http.post('deleteCampaignData', data);
    }

    deleteTactic(data) {
        return this._http.post('deleteTacticData', data);
    }

    loadAllData(data) {
        return this._http.post('loadAllData', data);
    }

    loadAdvSearchData(data) {
        return this._http.post('loadAllData', data);
    }

    loadAdvSearchDatabyProgram(data){
        return this._http.post("getprogramsbysearch",data);
    }
    loadAdvSearchDatabyTactic(data){
        return this._http.post("gettacticsbysearch",data);
    }

    getDraftsData(data) {
        return this._http.post('getDraftsData', data);
    }

    saveTacticDetail(data) {
        return this._http.post('saveTacticDetails', data);
    }

    loadDropdowns(data) {
        return this._http.post('getAllTacticDropdownValues', data);
    }

    getWaveDetail(waveId): Observable<any> {
        return this._http.get('getWaveDetailByTacticId', waveId);
    }
    tacticHistoryTable(data) {
        return this._http.get('edit_log_history', data);
    }
    getVersionList(cpcode): Observable<any> {
        return this._http.get('getVersionList', cpcode);
    }

    deleteWaveDetail(data) {
        return this._http.post('deleteWaveDetailData', data);
    }

    saveWaveDetailData(data) {
        return this._http.post('addWaveDetails', data);
    }

    setHistoryData(data) {
        return this._http.post('setTacticData', data);
    }

    saveExposureDetail(data) {
        return this._http.post('addwaveofferdetails', data);
    }

    getExposureDetail(id) {
        return this._http.get('getExposureDetail', id);
    }

    createProgram(data) {
        return this._http.post('addProgram', data);
    }

    /** Create Campaign API Call */
    saveNewCampaign(data) {
        return this._http.post('saveNewCampaign', data);
    }

    // -----==========  Ends Here -----================ */

    loadAllTilesData(data) {
        return this._http.post('loadAllTilesData', data);
    }

    getExtracts(params) {
        return this._http.post('getExtracts', params);
    }

    getExpCodesInTacticId(params) {
        return this._http.post('getExpCodesInTacticId', params);
    }

    loadAllNotification() {
        return this._http.get('getNotifications');
    }

    loadUserNotification() {
        return this._http.get('getUserNotifications');
    }

    deleteUserNotification(data) {
        return this._http.post('deleteUserNotification', data);
    }

    deleteNotification(data) {
        return this._http.post('deleteNotification', data);
    }

    setSeenNotifications(data) {
        return this._http.post('setSeenNotifications', data);
    }

    /** Bulk Uplad */
    sendBulkUploadDetails(details: any) {
        this.bulkUploadDetails.next(details);
    }

    getBulkUploadStatus(): Observable<any> {
        return this.bulkUploadStatus.asObservable();
    }

    getBulkUploadDetails(): Observable<any> {
        return this.bulkUploadDetails.asObservable();
    }

    uploadFile(data) {
        return this._http.post('bulkUpload', data);
    }
    bulkUploadStatusCheck(data) {
        return this._http.post('uploadStatusCheck', data);
    }

    commonPostService(url, input, isformData = 1) {
        return this._http.commonPostService(url, input, isformData);
    }
    sendBulkUploadStatus(status: boolean) {
        this.bulkUploadStatus.next(status);
    }

    getCpDropdowns(isImbruvica) {
        return this._http.get('getCpcodedropdowns', isImbruvica);
    }

    getCpcodedetails(params) {
        return this._http.post('getCpcodedetails', params);
    }
    // jet download
    getJetURL(file_name) {
        return this._http.get('jetfiledownload', file_name);
    }
    getAuditURL(file_name) {
        return this._http.get('auditfiledownload', file_name);
    }
    // Training video download
    getTrainingVideo(file_name) {
        return this._http.get('trainingVideo', file_name);
    }

    getJetFileNames() {
        return this._http.get('getjetfiles');
    }

    getAuditReports() {
        return this._http.get('getAuditReports');
    }
      // share Campaign
    shareCampaign(payload) {
        return this._http.post('shareCampaign', payload);
    }
    
    getAssetData(payload) {
        return this._http.post('getAssetData', payload);
    }

    getFileContent(payload) {
        this.getFile(payload).subscribe((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = payload['file_name'];
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }), error => console.log(error)
    }

    getFile(payload) {
        return this._http.post('getFileContent', payload)
    }
}

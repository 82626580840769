import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthTokens } from 'src/app/models';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    tokens: AuthTokens;

    constructor(private router: Router) {
        AppStateService.appState.subscribe(value => {
            if (value && value.auth_tokens) {
                this.tokens = value.auth_tokens;
            }
        });
    }

    private checkTokenValidity() {
        if (!environment.production) {
            return true;
        } else {
            if (this.tokens.login_timestamp && this.tokens.expires_in) {
                if (new Date().getTime() / 1000 - this.tokens.login_timestamp < this.tokens.expires_in) {
                    return true;
                }
            }
            return false;
        }
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const userDataJsonString = localStorage.getItem('userdata');
        const userDataParsed = JSON.parse(userDataJsonString);
        if (
            this.tokens &&
            Object.keys(this.tokens).length &&
            userDataJsonString !== null &&
            userDataJsonString !== undefined
        ) {
            try {
                if (!this.checkTokenValidity()) {
                    return this.router.createUrlTree(['/auth', 'session-timeout']);
                }
                if (userDataParsed.allRolesId.includes(1) || userDataParsed.allRolesId.includes(5)) {
                    if (window.location.hash.includes('manage-requests')) {
                        return false;
                    }
                }
                return true;
            } catch (e) {
                console.error(e);
                return this.router.createUrlTree(['/auth', 'unauthorized']);
            }
        } else {
            return this.router.createUrlTree(['/auth', 'unauthorized']);
        }
    }
}

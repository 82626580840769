import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { AuthTokens } from 'src/app/models';
import { AppStateService } from './app-state.service';
import { IfStmt } from '@angular/compiler';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private tokens: AuthTokens;
    private isCustomLogin: boolean;

    constructor() {
        AppStateService.appState.subscribe(val => {
            if (val['auth_tokens']) {
                this.tokens = val['auth_tokens'];
            }
            // added code for customlogin
            if(val['isCustomLogin']){
                this.isCustomLogin = JSON.parse(val['isCustomLogin']);
            }
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.handleAccess(request, next));
    }

    private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
        let changedRequest = request;
        const headerSettings: { [name: string]: string | string[] } = {};
        for (const key of request.headers.keys()) {
            headerSettings[key] = request.headers.getAll(key);
        }
        if (this.tokens && Object.keys(this.tokens).length > 0 && !headerSettings['Authorization']) {
            if(!this.isCustomLogin) {
            headerSettings['Authorization'] = 'Bearer ' + this.tokens.access_token;
            } else {
                headerSettings['Authorization'] = this.tokens.access_token;
            }
        }
        const newHeader = new HttpHeaders(headerSettings);
        if (
        (request.url.includes('getAssetData') && request.method === 'POST') || 
        (request.url.includes('jetfiledownload') && request.method === 'GET') ||
        (request.url.includes('auditfiledownload') && request.method === 'GET') ||
        (request.url.includes('getfilecontent') && request.method === 'POST') ||
        (request.url.includes('taxonomysheetdownload') && request.method === 'GET')
        ) {
            // Clone the request with responseType set to 'blob'
            changedRequest = request.clone({
              headers: newHeader,
              responseType: 'blob' as 'json' // Explicitly cast to 'json' for TypeScript
            });
          }
          else if(request.url.includes('trainingVideo') && request.method === 'GET'){
            changedRequest = request.clone({
                headers: newHeader,
                responseType: 'blob' 
              });
          }
          else{
            changedRequest = request.clone({
                headers: newHeader,
               
            });

          }
      
        return next.handle(changedRequest).toPromise();
    }
}

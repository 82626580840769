import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { timer } from 'rxjs/observable/timer';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SelectivePreloadingStrategyService implements PreloadingStrategy {
  preloadedModules: string[] = [];

  preload(route: Route, load: Function): Observable<any> {
    const loadRoute = (delay) => delay
        ? timer(300).pipe(flatMap(_ => load()))
        : load();
    return route.data && route.data.preload 
        ? loadRoute(route.data.delay)
        : of(null);
  }
}
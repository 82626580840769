import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppStateService {
    static appState = new BehaviorSubject(null);

    constructor() {
        let gbstate = window.sessionStorage.getItem('globalState');
        if (gbstate === null || gbstate === undefined) {
            gbstate = JSON.parse('{}');
        } else {
            if (typeof gbstate === 'string') {
                try {
                    gbstate = JSON.parse(gbstate);
                } catch (e) {
                    gbstate = JSON.parse('{}');
                }
            }
        }
        AppStateService.appState.next(gbstate);
    }

    // Exposed Functions --------------------------------------------------------------------- //
    public setGlobalState<T>(key: string, value: T) {
        // Check Global State Definition
        let temp = AppStateService.appState.getValue();

        if (temp === null || temp === undefined) {
            temp = {};
        } else {
            if (typeof temp === 'string') {
                try {
                    temp = JSON.parse(temp);
                } catch (e) {
                    temp = {};
                }
            } else {
                try {
                    temp = JSON.stringify(temp);
                    temp = JSON.parse(temp);
                } catch (e) {
                    temp = {};
                }
            }
        }

        // Set Global State Value
        try {
            temp[key] = value;
        } catch (e) {
            temp = {};
            temp[key] = value;
        }

        window.sessionStorage.setItem('globalState', JSON.stringify(temp));
        AppStateService.appState.next(temp);
    }

    public removeGlobalState(key) {
        let temp = AppStateService.appState.getValue();
        if (temp === null || temp === undefined) {
            temp = {};
        } else {
            if (typeof temp === 'string') {
                try {
                    temp = JSON.parse(temp);
                } catch (e) {
                    temp = {};
                }
            } else {
                try {
                    temp = JSON.stringify(temp);
                    temp = JSON.parse(temp);
                } catch (e) {
                    temp = {};
                }
            }
        }
        delete temp[key];

        AppStateService.appState.next(temp);
        window.sessionStorage.setItem('globalState', JSON.stringify(temp));
    }

    public clearGlobalState() {
        AppStateService.appState.next({});
        window.sessionStorage.clear();
    }
}

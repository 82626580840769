export const StringConstants = {
    MANAGER_ROLE: 1,
    ADMIN_ROLE: 2,
    CREATIVE_ROLE: 3,
    UPDATE_USER_MESSAGE: 'user updated successfully!',
    APPROVE: 'approved',
    REJECT: 'rejected',
};

export const ColumnList = [
    'Program',
    'Program Description',
    'Program Objective',
    'Program Start Date',
    'Program End Date',
    'Market',
    'Therapeutic Area',
    'Campaign Name',
    'Brand',
    'Campaign Goal',
    'Campaign Description',
    'Campaign Objective',
    'Customer Type',
    'Campaign Start Date',
    'Campaign End Date',
    'Tactic Description',
    'Placement Name',
    'Indication',
    'Content Type',
    'Channel Category',
    'Channel',
    'Placement Id',
    'CP/JOTS IMB Document ID',
    'CP/JOTS IMB Document ID Version',
    'Expiration Date',
    'Approved Content',
    'Key Message Category',
    'Key Message Sub Category',
    'Study/Indication',
    'Subject Line',
    'Tactic Type',
    'Execution Vendor',
    'PROACT Message Label',
    'PROACT Message Number',
    'Placement Start Date',
    'Placement End Date',
    'Offer Type',
    'Link',
    'Content Tag',
    'UTM Tag',
    'Exposure Code',
    'Tactic Status',
];
export const MandatoryColumns = [
    'Campaign Name',
    'Brand',
    'Campaign Goal',
    'Campaign Description',
    'Campaign Objective',
    'Customer Type',
    'Campaign Start Date',
    'Campaign End Date',
    'Tactic Description',
    'Placement Name',
    'Indication',
    'Content Type',
    'Channel Category',
    'Channel',
    'Placement Id',
    'CP/JOTS IMB Document ID',
    'CP/JOTS IMB Document ID Version',
    'Expiration Date',
    'Approved Content',
    'Key Message Category',
    'Key Message Sub Category',
    'Study/Indication',
    'Subject Line',
    'Tactic Type',
    'Execution Vendor',
    'PROACT Message Label',
    'PROACT Message Number',
    'Placement Start Date',
    'Placement End Date',
    'Offer Type',
    'Link',
    'Content Tag',
    'CP Code',   // Canada column
    'CP Version Number', // Canada column
    'Intended Use Start Date', // Canada column
    'Intended Use End Date', // Canada column
];
export const errorMessages = {
    InputLimit: 'Please only use 500 characters',
    TextAreaLimit: 'Please only use 500 characters',
    ClearErrors: 'There are some errors please fix them before continuing',
};

export const newRequestTooltipContent = {
    INTERNALUSER: 'User has a JNJ email address',
    EXTERNALUSER: 'User does not have a JNJ email address and will be using an external address to log-in',
    EXTERNALAGENCY : 'Agency that this user belongs to (typically a Creative Agency)',
    MARKET: 'Country that the user will be active in',
    THERAPEUTICAREA: 'Primary therapeutic area the user will be active in and needs access to',
    BRANDS: 'Primary brands the user will be active in and needs access to',
};

export const addNewProgramTooltipContent = {
    PROGRAMNAME:
        'Name of the Program (a distinct collection of campaigns sharing a common objective).  An example can be "Invokana 2023 Marketing Strategy", or "Janssen Pro Launch Program"',
    THERAPEUTICAREA: 'Primary therapeutic area of the overall program',
    PROGRAMOBJECTIVE: 'Summary of the broad objective & purpose of the program',
    MARKET: 'Country that the program will be deployed to',
    STARTDATE: 'Start date of the program',
    ENDDATE: 'End date of the program',
    ONGOING: 'Select it if the program does not have an end date',
    DESCRIPTION: 'More detailed description of the program ',
};

export const addNewCampaignTooltipContent = {
    CAMPAIGNNAME:
        'Name of the Campaign (a distinct collection of tactics sharing a common objective, within a broader program).For example, "Erleada Timed Head Office Campaign", or "Akeega Launch Campaign" can be examples of campaigns',
    BRAND: 'The brand of focus for the campaign',
    CAMPAIGNOBJECTIVE: 'Objective of the campaign ',
    CAMPAIGNDESCRIPTION: 'Optional: More detailed description of the campaign',
    CUSTOMERTYPE: 'The customer type who the campaign is intended to reach or who will receive the content',
    STARTDATE: 'Start Date of the Campaign',
    ENDDATE: 'End Date of the Campaign',
};

export const addNewTacticsTooltipContent = {
    //step 1
    TACTICDESCRIPTION: 'Summary/name of the tactic/content being sent',
    CONTENTTYPE: 'Medium of the content',
    CHANNELCATEGORY: 'Type of channel for the communication',
    CHANNEL: 'The medium for communication of the tactic',

    //step 2
    CPCODE: 'iMR code for the asset (i.e. CP-123456).  Only "Approved" assets will be allowed to be selected',
    EXPIRATIONDATE: 'Date of expiration of the asset, from iMR',
    APPROVEDCONTENT: 'Status of approval of the content, from iMR',
    INDICATION: 'List of indications that brand is approved for as listed in product monograph',
    ICONNECTPRODUCT: 'The iConnect product that this tactic is associated with',
    CONTENTCATEGORY:
        'Categories of the Key Message conveyed by the content (brand agnostic list of message categories)',
    KMC:'Primary themes or subjects (key messages) of your marketing asset that can classify them into distinct groups or categories.',
    KMSC:'Secondary themes or subjects (key messages) of your marketing asset that can classify them into distinct groups or categories.',
    STUDY_INDICATION: 'A study is a clinical trial or research project conducted to evaluate the safety, efficacy, or other aspects of a pharmaceutical product or medical intervention. An indication is a specific medical condition, symptom, or disease for which a pharmaceutical product is approved or recommended for use.',
    INDICATION_US: 'A specific medical condition, symptom, or disease for which a pharmaceutical product is approved or recommended for use.',
    SUBJECTLINE: 'Email Subject line',
    TACTICTYPE: 'Detailed channel / method of delivery',
    EXECUTIONVENDOR: 'Organization that will be deploying the asset',
    EXPOSURECODE: 'Unique code generated to track the deployments',
    PLACEMENTSTARTDATE: 'Start Date of the tactic',
    PLACEMENTENDDATE: 'End Date of the tactic',
    ICONNECTKEYMESSAGE: 'Brand specific key messages from iConnect to provide further insight on content category',
    CREATIVEAGENCY: 'Agency responsible for the creation of the content',
    CPVERSIONNUMBER: 'Version number of the tactic, from iMR',
    APPLICABLEPROVINCES: 'Provinces where the messaging is applicable',
    APPLICABLEAUDIENCE: 'Audience intended to receive the message',
    LANGUAGE: 'Language in which the creative asset was developed',
    BRANDSEGMENT: 'TA specific segment that the tactic is being sent to ',
    TACTICBRANDING: 'Type of Campaign (Branded/Unbranded)',
    COMPLEXITY: 'The knowledge level of the intended audience of the asset',
    AESTHETIC: 'Indicates the primary/ majority format of the finished asset',
    TONE: 'The general message type of the content message',
    INCLUDEFORNBA: 'Yes or No on whether to include the asset in the Next Best Action algorithm',

    //step 3
    OFFERTYPE: 'What the link provides/offers the end user',
    LINK: 'Web address relevant to the content',
    CONTENTTAG: 'Short descriptor of the offer to appear in UTM tag',
    EXPOSURECODEE: 'The specific "Exposure Details" that this link is affilitated with',
    UTMTAG: 'URL for google analytics tracking to later identify which web visitors came from which campaign',
};

export const MARKETS = {
    US: 'us',
    CANADA: 'canada',
};

export const CanadaColumnList = [
    'Program',
    'Program Description',
    'Program Objective',
    'Program Start Date',
    'Program End Date',
    'Market',
    'Therapeutic Area',
    'Campaign Name',
    'Brand',
    'Campaign Goal',
    'Campaign Objective',
    'Customer Type',
    'Campaign Start Date',
    'Campaign End Date',
    'Tactic Description',
    'Placement Name',
    'Content Type',
    'Channel Category',
    'Channel',
    'Placement Id',
    'CP Code',
    'CP Version Number',
    'IMR Asset URL',
    'Expiration Date',
    'Approved Content',
    'Subject Line',
    'Tactic Type',
    'Execution Vendor',
    'Intended Use Start Date',
    'Intended Use End Date',
    'Language',
    'Creative Agency',
    'Indication CA',
    'Content Category',
    'iConnect Product',
    'iConnect Key Message',
    'Brand Segments',
    'Complexity',
    'Aesthetic',
    'Tone',
    'Applicable Provinces',
    'Applicable Audience',
    'Tactic Branding',
    'Include for NBA',
    'Offer Type',
    'Link',
    'UTM Content Tag',
    'UTM URL',
    'Exposure Code',
    'Tactic Status',
];
export const CANADA_CPCODE_TABLE = [
    {
        columnname: 'Document Name',
        columntype: 'plaintext',
        columndatakey: 'document_name',
        isdisplayed: true,
        isdisabled: true,
        sortable: true,
        flexcolumn: true,
        width: '300px',
        tableheaderstyle: {
            cursor: 'pointer'
        },
        style: {
            cursor: 'text'
        }
    },
    {
        columnname: 'Item Description',
        columntype: 'plaintext',
        columndatakey: 'description',
        isdisplayed: true,
        isdisabled: true,
        sortable: true,
        flexcolumn: true,
        width: '700px',
        tableheaderstyle: {
            cursor: 'pointer'
        },
        style: {
            cursor: 'text'
        }
    },
    {
        columnname: 'Status',
        columntype: 'plaintext',
        columndatakey: 'status',
        isdisplayed: true,
        isdisabled: true,
        sortable: true,
        flexcolumn: true,
        width: '150px',
        tableheaderstyle: {
            cursor: 'pointer'
        },
        style: {
            cursor: 'text'
        }
    },
    {
        columnname: 'Type',
        columntype: 'plaintext',
        columndatakey: 'type',
        isdisplayed: true,
        isdisabled: true,
        sortable: true,
        flexcolumn: true,
        width: '150px',
        tableheaderstyle: {
            cursor: 'pointer'
        },
        style: {
            cursor: 'text'
        }
    },
    {
        columnname: 'Subtype',
        columntype: 'plaintext',
        columndatakey: 'subtype',
        isdisplayed: true,
        isdisabled: true,
        sortable: true,
        flexcolumn: true,
        width: '100px',
        tableheaderstyle: {
            cursor: 'pointer'
        },
        style: {
            cursor: 'text'
        }

    },
    {
        columnname: 'Business Unit',
        columntype: 'plaintext',
        columndatakey: 'business_unit',
        isdisplayed: true,
        isdisabled: true,
        sortable: true,
        flexcolumn: true,
        width: '150px',
        tableheaderstyle: {
            cursor: 'pointer'
        },
        style: {
            cursor: 'text'
        }
    },
    {
        columnname: 'Owner',
        columntype: 'plaintext',
        columndatakey: 'owner',
        isdisplayed: true,
        isdisabled: true,
        sortable: true,
        flexcolumn: true,
        width: '300px',
        tableheaderstyle: {
            cursor: 'pointer'
        },
        style: {
            cursor: 'text'
        }
    },
    {
        columnname: 'Agency Name',
        columntype: 'plaintext',
        columndatakey: 'agency',
        isdisplayed: true,
        isdisabled: true,
        sortable: true,
        flexcolumn: true,
        width: '300px',
        tableheaderstyle: {
            cursor: 'pointer'
        },
        style: {
            cursor: 'text'
        }
    },
    {
        columnname: 'Language',
        columntype: 'plaintext',
        columndatakey: 'language',
        isdisplayed: true,
        isdisabled: true,
        sortable: true,
        flexcolumn: true,
        width: '300px',
        tableheaderstyle: {
            cursor: 'pointer'
        },
        style: {
            cursor: 'text'
        }
    },
    {
        columnname: 'Product',
        columntype: 'plaintext',
        columndatakey: 'product',
        isdisplayed: true,
        isdisabled: true,
        sortable: true,
        flexcolumn: true,
        width: '300px',
        tableheaderstyle: {
            cursor: 'pointer'
        },
        style: {
            cursor: 'text'
        }
    },{
        columnname: 'Approved For Distribution Date',
        columntype: 'plaintext',
        columndatakey: 'approved_dt',
        isdisplayed: true,
        isdisabled: true,
        sortable: true,
        flexcolumn: true,
        width: '300px',
        tableheaderstyle: {
            cursor: 'pointer'
        },
        style: {
            cursor: 'text'
        }
    },
    {
        columnname: 'First Use Date',
        columntype: 'plaintext',
        columndatakey: 'first_use_dt',
        isdisplayed: true,
        isdisabled: true,
        sortable: true,
        flexcolumn: true,
        width: '150px',
        tableheaderstyle: {
            cursor: 'pointer'
        },
        style: {
            cursor: 'text'
        }
    },
    {
        columnname: 'Expiration Date',
        columntype: 'plaintext',
        columndatakey: 'expiration_dt',
        isdisplayed: true,
        isdisabled: true,
        sortable: true,
        flexcolumn: true,
        width: '150px',
        tableheaderstyle: {
            cursor: 'pointer'
        },
        style: {
            cursor: 'text'
        }
    },
    {
        columnname: 'Asset URL',
        columntype: 'plaintext',
        columndatakey: 'asset_url',
        isdisplayed: true,
        isdisabled: true,
        sortable: true,
        flexcolumn: true,
        width: '150px',
        tableheaderstyle: {
            cursor: 'pointer'
        },
        style: {
            cursor: 'text'
        }
    }]
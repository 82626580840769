import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';

/*
 *  This is the file which contains exposable global common functions
 *  ready to be used inside the project.
 */
@Injectable({ providedIn: 'root' })
export class UtilsService {
    closeDialogBox = new Subject<any>();
    loadPrograms = new Subject<any>();

    constructor(protected asyncLocalStorage: LocalStorage, public router: Router) {}

    // Exposed Functions --------------------------------------------------------------------- //
    public setLocalStorageData(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    // function to get local storage data
    public getLocalStorageData(key) {
        return JSON.parse(localStorage.getItem(key));
    }

    /** Setting in the item in localstorage  in aync way */
    public setAsyncLocalStorageData(key, value) {
        return this.asyncLocalStorage.setItem(key, value); // subscribe it from component you're calling -- .subscribe(() => {});
    }

    /** Getting item from locastorage in async way  */
    public getAsyncLocalStorageData(key) {
        return this.asyncLocalStorage.getItem(key);
    }

    userTimeout() {
        localStorage.clear();
        this.closeDialogBox.next('close');
        this.router.navigateByUrl('/auth/session-timeout');
    }

    listenForTimeout(): Observable<any> {
        return this.closeDialogBox.asObservable();
    }

    refreshPrograms(showSuccessMessage) {
        this.loadPrograms.next(showSuccessMessage);
    }

    loadProgramsComponent(): Observable<any> {
        return this.loadPrograms.asObservable();
    }

    // common method for sort a list by key
    commonSortByKey(array, key, str) {
        return array.sort(function (a, b) {
            let x;
            let y;
            // numerical data
            if (typeof a[key] === 'number') {
                x = a[key];
                y = b[key];
            } else if (typeof (a[key] === 'string')) {
                x = a[key].trim().toLowerCase();
                y = b[key].trim().toLowerCase();
            } else if (str === 'date') {
                x = new Date(a[key]);
                x /= 1000 + 900 + 330 * 60;
                y = new Date(b[key]);
                y /= 1000 + 900 + 330 * 60;
            }
            return x < y ? -1 : x > y ? 1 : 0;
        });
    }

    // common method for reverse sort a list by key
    commonReverseSortByKey(array, key, str) {
        return array.sort(function (b, a) {
            let x;
            let y;
            // numerical data
            if (typeof a[key] === 'number') {
                x = a[key];
                y = b[key];
            } else if (typeof (a[key] === 'string')) {
                x = a[key].toLowerCase();
                y = b[key].toLowerCase();
            } else if (str === 'date') {
                x = new Date(a[key]);
                x /= 1000 + 900 + 330 * 60;
                y = new Date(b[key]);
                y /= 1000 + 900 + 330 * 60;
            }

            return x < y ? -1 : x > y ? 1 : 0;
        });
    }
    
    getMarket(){
        return JSON.parse(localStorage.getItem('userdata')).market
    }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiEndpoint } from 'src/app/core/constants/endpoints';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private http: HttpClient, private router: Router) {}

    delete(urlKey: string, useLocalJson = false): Observable<any> {
        return this.http.delete(this.parseApiUrl(urlKey, '', useLocalJson), { observe: 'response' }).pipe(
            map(resp => this.handleResponse(resp)),
            catchError(this.handleError)
        );
    }

    /**
     * A wrapper method for http get request.
     * @param urlKey API Endpoint key.
     * @param urlArgument Argument to append to endpoint.
     * @param useLocalJson If `true`, fetches local .json file. Else, hits server URL mentioned in environment file.
     * @returns An `Observable` with the response body of type `T`.
     */
    getRequest<T>(url: string, urlArgument = '', useLocalJson = false): Observable<T> {
        return this.http.get<T>(this.parseApiUrl(url, urlArgument, useLocalJson));
    }

    // TODO: Replace this method and all usage with 'getRequest' since it has type parameter
    /**
     * A wrapper method for http get request.
     * @param urlKey API Endpoint key.
     * @param urlArgument Argument to append to endpoint.
     * @param useLocalJson If `true`, fetches local .json file. Else, hits server URL mentioned in environment file.
     * @returns An `Observable` with the response body of type `any`.
     */
    get(urlKey: string, urlArgument = '', useLocalJson = false): Observable<any> {
        return this.http.get(this.parseApiUrl(urlKey, urlArgument, useLocalJson), { observe: 'response' }).pipe(
            map(resp => this.handleResponse(resp)),
            catchError(this.handleError.bind(this))
        );
    }

    post(urlKey: string, payload: any, urlArgument = undefined, useLocalJson = false, options?): Observable<any> {
        let removeSlash = false;
        if (urlKey === 'resetpassword' || urlKey === 'userresetpassword') {
            removeSlash = true;
        }

        return this.http
            .post(this.parseApiUrl(urlKey, urlArgument, useLocalJson, removeSlash), payload, {
                observe: 'response',
            })
            .pipe(
                map(resp => this.handleResponse(resp)),
                catchError(this.handleError.bind(this))
            );
    }

    put(urlKey: string, payload: any, useLocalJson = false): Observable<any> {
        return this.http.put(this.parseApiUrl(urlKey, '', useLocalJson), payload, { observe: 'response' }).pipe(
            map(resp => this.handleResponse(resp)),
            catchError(this.handleError.bind(this))
        );
    }

    commonPostService(url, input, isFormData = 1) {
        if (isFormData === 1) {
            const formData = new FormData();
            for (const key of input) {
                formData[key] = input[key];
            }
            const httpOptions = {
                headers: new HttpHeaders().set('Content-Type', 'multipart/form-data'),
            };
            return this.http.post(this.parseApiUrl(url), formData, httpOptions);
        } else {
            return this.http.post(url, input, {
                observe: 'body',
                responseType: 'json',
            });
        }
    }

    private handleError(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        // TODO: Check if user is Admin using user details
        // if (window.location.href.includes('manage-requests')) {
        //     window.location.href = `${environment.redirect_uri}/manage-requests`;
        // } else {
        //     window.location.href = `${environment.redirect_uri}/metadata-view/activity/home`;
        // }
        if (error.status === 401) {
            this.router.navigateByUrl('/auth/unauthorized');
        } else {
            return throwError(errorMessage);
        }
    }

    private handleResponse(resp) {
        if (resp.status === 200 || resp.status === 201 || resp.status === 202) {
            return resp.body;
        } else {
            window.alert(resp.statusText);
            return resp;
        }
    }

    /**
     * Constructs final API endpoint URL.
     * @param urlKey API Endpoint key.
     * @param urlArgument Argument to append to endpoint.
     * @param useLocalJson If `true`, fetches local .json file. Else, hits server URL mentioned in environment file.
     * @returns Final API endpoint URL.
     */
    private parseApiUrl(urlKey: string, urlArgument = '', useLocalJson = false, removeSlash = false) {
        let baseUrl = environment.serverUrl;
        const endpoint = ApiEndpoint[urlKey] || '';
        if (useLocalJson) {
            return `assets/json/${endpoint}.json`;
        }
        if (removeSlash) {
            return `${baseUrl}api/${endpoint}${urlArgument}`;
        } else {
            return `${baseUrl}api/${endpoint}${urlArgument && '/' + urlArgument}`;
        }
    }
}

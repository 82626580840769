import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FetchApiDataService } from './metadata-view/utilities/fetch-api-data.service';
import { AuthTokens, OAuthTokenAPIResponse } from './models';
import { AppStateService } from './shared/services/app-state.service';
import { UtilsService } from './shared/services/utils.service';
import { Base64Convertor } from './shared/utils/base64-converter.helper';
import { StorageService } from './shared/services/storage.service';
import { takeWhile } from 'rxjs/operators';
import { MARKETS } from 'src/environments/StringConstants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    bulkUploadDetails: Subscription;
    subscription: Subscription;
    showMessage = false;
    showSuccessMessage = false;
    showErrorMessage = false;
    api_error_message: any = '';
    showProgressMessage = false;
    logUrl: string = '';
    uploadCounter  = 0

    tokens: AuthTokens;
    refreshTokenInterval: NodeJS.Timer;
    private appStateSubscription: Subscription;
    isInitialized = false;
    currentMarket = '';
    MARKETS = MARKETS;

    constructor(
        private service: FetchApiDataService,
        private utilService: UtilsService,
        private http: HttpClient,
        private storage: StorageService,
        @Inject(AppStateService) private appState: AppStateService
    ) { }

    ngOnInit() {
        this.setUpBulkUploadSubscription();
        this.setUpAppStateSubscription();
        //  //checking market of logged in user
        //  this.currentMarket = this.utilService.getMarket();
        
    }

    private setUpBulkUploadSubscription(): void {
        this.api_error_message = [];
        let that = this;
        this.bulkUploadDetails = this.service.getBulkUploadDetails().subscribe(uploadData => {
            // this.service.sendBulkUploadStatus(true);
            if(uploadData){
                this.uploadCounter += 1;
            }
                this.subscription = interval(30000)
                .pipe(
                  takeWhile(() => this.uploadCounter > 0)
                )
                .subscribe(val => {
                  this.bulkUploadStatusCheck();
                });
        });
        
    }
    bulkUploadStatusCheck(): void {
        if(this.uploadCounter<=0){
            this.subscription.unsubscribe();
            this.bulkUploadDetails.unsubscribe();
            return;
        }
        const userInfo = this.storage.getStorage('userdata');
        const payLoad = {
            user_id: userInfo.email_address
        }
        this.service.bulkUploadStatusCheck(payLoad).subscribe(
            response => {
               
                this.api_error_message = response.message.split('<br>');
                if (this.api_error_message.slice(-1) == '') {
                    this.api_error_message.pop()
                }
                if (response.status === 'Completed' || response.status === 'Error' ||  response.status.toLowerCase() === 'processed') {
                    // this.service.sendBulkUploadStatus(true);
                    this.showMessage = true;

                    // this.api_error_message = response.message;
                    if (response.status === 'Completed') {
                        this.showSuccessMessage = true;
                        this.showErrorMessage = false;
                        this.showProgressMessage = false
                        if (response.export_file_path) {
                            this.logUrl = response.export_file_path;
                        }
                    }
                    if (response.status === 'Error'  ||  response.status.toLowerCase() === 'processed') {
                        if (response.error_file_path) {
                            this.logUrl = response.error_file_path;
                        }else{
                            this.logUrl = "";
                        }
                        this.showSuccessMessage = false;
                        this.showErrorMessage = true;
                        this.showProgressMessage = false;
                    }
                    this.subscription && this.subscription.unsubscribe();
                    this.uploadCounter -= 1 ;                 
                    
                } else {
                    // this.service.sendBulkUploadStatus(false);
                    if (!this.showProgressMessage) {
                        this.showMessage = true;
                    }
                    // this.api_error_message = response.message;
                    // this.showMessage = true;
                    this.showSuccessMessage = false;
                    this.showErrorMessage = false;
                    this.showProgressMessage = true;
                }
            },
            () => {
                // this.service.sendBulkUploadStatus(false);
                this.api_error_message = ['Invalid file format.'];
                this.showMessage = true;
                this.showSuccessMessage = false;
                this.showErrorMessage = true;
            }
        );
    }

    private setUpAppStateSubscription(): void {
        this.appStateSubscription = AppStateService.appState.subscribe(val => {
            if (val && Object.keys(val).length > 0) {
                if (!this.isInitialized && val.auth_tokens && val.auth_tokens.refresh_token) {
                    this.tokens = JSON.parse(JSON.stringify(val.auth_tokens));
                    this.isInitialized = true;
                    if (environment.isPingEnabled && environment.production) {
                        this.getRefreshToken();
                        this.setRefreshInterval();
                    }
                }
            } else {
                this.isInitialized = false;
                clearInterval(this.refreshTokenInterval);
            }
        });
    }

    downloadLogFile() {
        window.open(this.logUrl, '_target');
    }

    closeUpload() {
        if (this.showSuccessMessage) {
            this.utilService.refreshPrograms(this.showSuccessMessage);
        }
    }

    /**
     * Updates token after every 30 minutes.
     */
    private setRefreshInterval() {
        if (environment.production) {
            this.refreshTokenInterval = setInterval(() => {
                this.getRefreshToken();
            }, 30 * 60 * 1000);
        }
    }

    private getRefreshToken() {
        const { client_id, client_secret, redirect_uri, tokenUrl } = environment;
        const params = new HttpParams({
            fromObject: {
                grant_type: 'refresh_token',
                client_id,
                client_secret: Base64Convertor.decode(client_secret),
                refresh_token: this.tokens.refresh_token,
                redirect_uri,
            },
        });
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
        });

        this.http.post<OAuthTokenAPIResponse>(tokenUrl, params, { headers }).subscribe(
            response => {
                if (response && response.error) {
                    // TODO: Handle Error
                } else if (response && response.access_token && response.expires_in && response.refresh_token) {
                    this.tokens = {
                        access_token: response.access_token,
                        expires_in: response.expires_in,
                        login_timestamp: new Date().getTime() / 1000,
                        refresh_token: response.refresh_token,
                    };
                    this.appState.setGlobalState<AuthTokens>('auth_tokens', JSON.parse(JSON.stringify(this.tokens)));
                } else {
                    console.error('Uncaught error.');
                }
            },
            error => {
                // TODO: Handle Error
                // TODO: Remove console statement
                console.error(error);
            }
        );
    }

    ngOnDestroy(): void {
        this.appStateSubscription.unsubscribe();
        clearInterval(this.refreshTokenInterval);
    }
}

export const environment = {
    production: true,
    serverUrl: 'https://dev.api.jots.jnj.com/',
    pingUrl: 'https://fedlogin.jnj.com/',
    authUrl: 'https://fedlogin.jnj.com/as/authorization.oauth2',
    tokenUrl: 'https://fedlogin.jnj.com/as/token.oauth2',
    revokeUrl: 'https://fedlogin.jnj.com/as/revoke_token.oauth2',
    client_id: 'jots_dev',
    client_secret: 'aUJWOUVYdW0wclVQMzVhMUw4VEl6MlBacTFGWW5Cd1A4RXZSTWtYcXhkbml6S3lXdUNXNEVtSmdRMk95amVieg==',
    redirect_uri: 'https://dev.jots.jnj.com',
    isPingEnabled: true,
    isUsernamePasswordEnabled: true,
};

export class Base64Convertor {
    private static _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

    static encode(e: string) {
        let t = '';
        let n: number, r: number, i: number, s: number, o: number, u: number, a: number;
        let f = 0;
        e = this.encodeUTF8(e);
        while (f < e.length) {
            n = e.charCodeAt(f++);
            r = e.charCodeAt(f++);
            i = e.charCodeAt(f++);
            // tslint:disable-next-line:no-bitwise
            s = n >> 2;
            // tslint:disable-next-line:no-bitwise
            o = ((n & 3) << 4) | (r >> 4);
            // tslint:disable-next-line:no-bitwise
            u = ((r & 15) << 2) | (i >> 6);
            // tslint:disable-next-line:no-bitwise
            a = i & 63;
            if (isNaN(r)) {
                u = a = 64;
            } else if (isNaN(i)) {
                a = 64;
            }
            t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a);
        }
        return t;
    }

    static encodeUTF8(e: string) {
        e = e.replace(/\r\n/g, 'n');
        let t = '';
        for (let n = 0; n < e.length; n++) {
            // tslint:disable-next-line:no-bitwise
            const r = e.charCodeAt(n);
            if (r < 128) {
                t += String.fromCharCode(r);
            } else if (r > 127 && r < 2048) {
                // tslint:disable-next-line:no-bitwise
                t += String.fromCharCode((r >> 6) | 192);
                // tslint:disable-next-line:no-bitwise
                t += String.fromCharCode((r & 63) | 128);
            } else {
                // tslint:disable-next-line:no-bitwise
                t += String.fromCharCode((r >> 12) | 224);
                // tslint:disable-next-line:no-bitwise
                t += String.fromCharCode(((r >> 6) & 63) | 128);
                // tslint:disable-next-line:no-bitwise
                t += String.fromCharCode((r & 63) | 128);
            }
        }
        return t;
    }

    static decode(e: string) {
        let t = '';
        let n: number, r: number, i: number;
        let s: number, o: number, u: number, a: number;
        let f = 0;
        e = e.replace(/[^A-Za-z0-9\+\/\=]/g, '');
        while (f < e.length) {
            s = this._keyStr.indexOf(e.charAt(f++));
            o = this._keyStr.indexOf(e.charAt(f++));
            u = this._keyStr.indexOf(e.charAt(f++));
            a = this._keyStr.indexOf(e.charAt(f++));
            // tslint:disable-next-line:no-bitwise
            n = (s << 2) | (o >> 4);
            // tslint:disable-next-line:no-bitwise
            r = ((o & 15) << 4) | (u >> 2);
            // tslint:disable-next-line:no-bitwise
            i = ((u & 3) << 6) | a;
            t = t + String.fromCharCode(n);
            if (u !== 64) {
                t = t + String.fromCharCode(r);
            }
            if (a !== 64) {
                t = t + String.fromCharCode(i);
            }
        }
        t = this.decodeUTF8(t);
        return t;
    }

    static decodeUTF8(e: string) {
        let t = '';
        let n = 0;
        let r = 0;
        const c1 = 0;
        let c2 = 0;
        let c3 = 0;
        while (n < e.length) {
            r = e.charCodeAt(n);
            if (r < 128) {
                t += String.fromCharCode(r);
                n++;
            } else if (r > 191 && r < 224) {
                c2 = e.charCodeAt(n + 1);
                // tslint:disable-next-line:no-bitwise
                t += String.fromCharCode(((r & 31) << 6) | (c2 & 63));
                n += 2;
            } else {
                c2 = e.charCodeAt(n + 1);
                c3 = e.charCodeAt(n + 2);
                // tslint:disable-next-line:no-bitwise
                t += String.fromCharCode(((r & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                n += 3;
            }
        }
        return t;
    }
}

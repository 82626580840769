import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards';
import { SelectivePreloadingStrategyService } from './app-loading-strategy';

const routes: Routes = [
    { path: '', redirectTo: 'auth', pathMatch: 'full' },
    {
        path: 'auth',
        loadChildren: './auth/auth.module#AuthModule',
    },
    {
        path: 'metadata-view',
        loadChildren: 'src/app/metadata-view/metadata-view.module#MetadataViewModule',
        canActivate: [AuthGuard],
        data: { preload: true },
    },
    {
        path: 'calendar-view',
        loadChildren: './campaign-calendar/campaign-calendar.module#CampaignCalendarModule',
        canActivate: [AuthGuard],
        data: { preload: true, delay: true },
    },
    {
        path: 'manage-requests',
        loadChildren: './manage-requests/manage-requests.module#ManageRequestsModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'user',
        loadChildren: './user/user.module#UserModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'home',
        loadChildren: './landing/landing.module#LandingModule',
        canActivate: [AuthGuard],
    },
    { path: 'error-info', loadChildren: './error-info/error-info.module#ErrorInfoModule' },
    { path: '**', redirectTo: 'error-info', pathMatch: 'full' },
];

const routingConfig: ExtraOptions = {
    useHash: false,
    preloadingStrategy: SelectivePreloadingStrategyService,
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routingConfig)],
    exports: [RouterModule],
    providers: [SelectivePreloadingStrategyService],
})
export class AppRoutingModule {}

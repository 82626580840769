import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserDetailsApiResponse } from 'src/app/core/models';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
    providedIn: 'root',
})
export class AdminGuard implements CanActivate {
    constructor(private httpService: HttpService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const userDetails = JSON.parse(localStorage.getItem('userdata'));
        if (userDetails && userDetails.allRolesId && userDetails.allRolesId.includes(2) && userDetails.profileId) {
            return this.httpService.getRequest<UserDetailsApiResponse>('getUserDetails', userDetails.profileId).pipe(
                map(({ result }) => {
                    if (
                        result &&
                        result.basic &&
                        result.basic.profile_id &&
                        result.basic.role_id &&
                        result.basic.role_id.includes(2)
                    ) {
                        return true;
                    }
                    return this.router.createUrlTree(['/auth', 'unauthorized']);
                }),
                catchError(() => of(this.router.createUrlTree(['/auth', 'unauthorized'])))
            );
        } else {
            return this.router.createUrlTree(['/auth', 'unauthorized']);
        }
    }
}
